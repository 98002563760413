<template>
    <div class="menu-list">
        <div @click="getMenu(index,item)" :class="menuIndex==index?'get-menu':''" v-for="(item,index) in menuList" :key="index">
            {{ $t('header.' + item) }}
        </div>
    </div>
</template>
<script setup>
    import { ref, reactive, onMounted } from 'vue';
    import { useRouter, useRoute } from 'vue-router';

    const menuIndex = ref(0)
    const menuList = reactive([
        'home','lp','award','solfi','user'
    ])

    const router = useRouter();
    const getMenu = (index,item) =>{
        router.push({
            name: item
        })
    }

    onMounted(() => {
        getMenuIndex()
    })
    const getMenuIndex = () =>{
        const route = useRoute()
        const item = route.name;
        const index = menuList.indexOf(item);
        if(index != -1){
            menuIndex.value = index;
        }
        if(item === 'lprecord') menuIndex.value = 1
    }
</script>
<style scoped>
    .menu-list{
        height: 40px;
        /* color: #1E293B;  */
        color: white;
        display: flex;
        align-items: center;
        margin-left: 34px;
        font-size: 16px;
        /* font-weight: bold; */
        /* background-color: #F2F2F9; */
        border-radius: 100px;
    }
    .menu-list div{
        width: 100px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .get-menu{
        color: #C280FF;
        /* background-color: #8C66FC; */
        /* border-radius: 100px; */
    }
</style>
