<template>
    <div class="language">
        <img @click="isLanguage()" src="../../static/images/language.png" />
        <div class="language-set" v-show="showLanguage">
            <div :class="item.value === language?'get-language':''" @click="handleLanguage(item.value)" v-for="(item,index) in languageList" :key="index">
                {{ item.name }}
            </div>
        </div>
    </div>
</template>
<script setup>
    import { ref, reactive, onMounted, onBeforeUnmount } from 'vue';
    import i18n from "@/i18n";

    const language = ref('');
    const showLanguage = ref(false);
    const outside = ref('');

    onMounted(()=>{
        getLanguage();
        addListener();
    })

    const addListener = () =>{
        document.body.addEventListener('click', function () {
            outside.value = true
        }, true)

        document.body.addEventListener('click', function () {
            if (outside.value) {
                showLanguage.value = false
            }
        })
    }
    const isLanguage = () => {
        outside.value = false;
        showLanguage.value = !showLanguage.value;
    }
    const  getLanguage = () => {
        language.value = localStorage.getItem('locale') || 'en' ;
    }
    const languageList = reactive([
        {name: 'English', value: 'en'},
        {name: '中文(简体)', value: 'cn'},
        {name: '中文(繁體)', value: 'tc'},
        {name: 'Русский', value: 'ru'},
        {name: 'ภาษาไทย', value: 'th'},
        {name: '한국어', value: 'rok'},
        {name: '日本語', value: 'jp'},
        {name: 'Tiếng Việt', value: 'vi'},
        {name: 'Indonesia', value: 'id'},
        {name: 'ພາສາລາວ', value: 'lao'}
    ])
    const handleLanguage = (value) => {
        i18n.global.locale = value;
        showLanguage.value = false;
        localStorage.setItem('locale',value);
        language.value = value
    }
    onBeforeUnmount(() => {
        document.body.removeEventListener('click',()=>{

        })
    })
</script>
<style scoped>
    .language{
        display: flex;
        align-items: center;
        position: relative;
        font-size: 14px;
    }
    .language img{
        width: 27px;
        height: 27px;
    }
    .language-set{
        width: 76px;
        position: absolute;
        top: 30px;
        left: -40px;
        color: black;
        background-color: #eee;
        border-radius: 5px;
        padding: 12px;
        padding-top: 20px;
    }
    .language-set div{
        height: 25px;
        line-height: 25px;
        border-bottom: 1px solid rgba(178, 178, 188, 0.36);;
    }
    .get-language{
        color: #8C66FC;
    }
    @media screen and (max-width: 768px){
        .language{
            font-size: 12px;
        }
        .language-set{
            left: -60px;
        }
    }
</style>
