export default {
	ethereum: {
		chainId: 1,
		chainName: 'Ethereum Mainnet',
		coinName: 'ETH',
		coinSymbol: 'ETH',
		coinDecimals: 18,
		rpcUrl: 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
		blockExplorerUrl: 'https://etherscan.io/'
	},
	goerli: {
		chainId: 5,
		chainName: 'Ethereum Goerli Test net',
		coinName: 'ETH',
		coinSymbol: 'ETH',
		coinDecimals: 18,
		rpcUrl: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
		blockExplorerUrl: 'https://goerli.etherscan.io/'
	},
	optimism: {
		chainId: 10,
		chainName: 'Optimism',
		coinName: 'ETH',
		coinSymbol: 'ETH',
		coinDecimals: 18,
		rpcUrl: 'https://mainnet.optimism.io',
		blockExplorerUrl: 'https://optimistic.etherscan.io'
	},
	bscTestnet: {
		chainId: 97,
		chainName: 'BNB Smart Chain Testnet',
		coinName: 'BNB',
		coinSymbol: 'BNB',
		coinDecimals: 18,
		rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545',
		blockExplorerUrl: 'https://testnet.bscscan.com'
	},
	bsc: {
		chainId: 56,
		chainName: 'Binance Smart Chain Mainnet',
		coinName: 'BNB',
		coinSymbol: 'BNB',
		coinDecimals: 18,
		rpcUrl: 'https://bsc-dataseed.binance.org',
		blockExplorerUrl: 'https://bscscan.com/'
	},
	polygon: {
		chainId: 137,
			chainName: 'Polygon Mainnet',
			coinName: 'MATIC',
			coinSymbol: 'MATIC',
			coinDecimals: 18,
			rpcUrl: 'https://polygon-mainnet.infura.io',
			blockExplorerUrl: 'https://polygonscan.com/'
	},
	arbitrumOne: {
		chainId: 42161,
		chainName: 'Arbitrum One',
		coinName: 'ETH',
		coinSymbol: 'ETH',
		coinDecimals: 18,
		rpcUrl: 'https://endpoints.omniatech.io/v1/arbitrum/one/public',
		blockExplorerUrl: 'https://arbiscan.io'
	},
	avalanche: {
		chainId: 43114,
		chainName: 'Avalanche C-Chain',
		coinName: 'AVAX',
		coinSymbol: 'AVAX',
		coinDecimals: 18,
		rpcUrl: 'https://avalanche-mainnet.infura.io',
		blockExplorerUrl: 'https://snowtrace.io/'
	}
}
